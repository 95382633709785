import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div id="timeout-warning-modal">
      <div class="modal-header">
        <h3 class="modal-title" id="modal-title">
          {{ 'rspTimeoutWarningTitle' | translate }}
        </h3>
      </div>
      <p class="modal-body" id="modal-body">
        {{ 'rspTimeoutWarningBody' | translate }}
      </p>
      <div class="modal-footer">
        <button
          class="btn btn-danger"
          (click)="activeModal.close()"
          type="button"
          id="timeout-warning-modal-button"
        >
          {{ 'rspButtonOk' | translate }}
        </button>
      </div>
    </div>
  `,
})
export class TimeoutWarningModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
