<div id="consent">
  <h1>
    {{ "rspConsentHeading" | translate }}
  </h1>
  <div
    class="consent-information"
    [innerHTML]="'rspConsentInformation' | translate"
  ></div>
  <div>
    <input
      id="consent-checkbox"
      type="checkbox"
      [(ngModel)]="hasGivenConsent"
    />
    <label
      for="consent-checkbox"
      [innerHTML]="'rspConsentCheckbox' | translate"
    >
    </label>
  </div>
  <div class="consent-buttons">
    <button
      id="give-consent"
      class="btn btn-primary"
      type="button"
      [disabled]="!hasGivenConsent"
      (click)="giveConsent()"
    >
      {{ "rspConsentButtonContinue" | translate }}
    </button>
  </div>
</div>
