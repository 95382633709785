import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
} from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';
import { AppRoutingModule } from './modules/routing/app-routing.module';
import {
  APP_BASE_HREF,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InitialInformationComponent } from './components/initial-information/initial-information.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { ConsentComponent } from './components/consent/consent.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { ResultComponent } from './components/result/result.component';
import { SignComponent } from './components/sign/sign.component';
import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigService } from './services/app-config-service/app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpInterceptorService } from './modules/http-interceptor/http-interceptor';
import { ModalComponent } from './components/modal/modal.component';
import { SaveAssessmentModalComponent } from './dialogs/save-assessment-modal';
import { TimeoutWarningModalComponent } from './dialogs/timeout-warning-modal';
import { QuestionnaireNotificationModalComponent } from './dialogs/questionaire-notification-modal';
import { ErrorComponent } from './components/error/error.component';
import { UrlConfigService } from './services/url-config-service/url-config.service';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { LanguageAttributeComponent } from './components/language-attribute/language-attribute.component';

export function appInit(appConfigService: AppConfigService) {
  return () => appConfigService.load();
}

export function HttpLoaderFactory(
  httpClient: HttpClient,
  appConfigService: AppConfigService,
  urlConfigService: UrlConfigService
) {
  return new TranslateHttpLoader(
    httpClient,
    urlConfigService.getThemingServiceTranslationUrl(
      appConfigService.configurationId
    ),
    ''
  );
}

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    WelcomeComponent,
    AuthenticateComponent,
    InitialInformationComponent,
    ConsentComponent,
    AssessmentComponent,
    ConfirmComponent,
    ResultComponent,
    SignComponent,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    SaveAssessmentModalComponent,
    TimeoutWarningModalComponent,
    QuestionnaireNotificationModalComponent,
    ErrorComponent,
    SpinnerOverlayComponent,
    LanguageAttributeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, AppConfigService, UrlConfigService],
      },
    }),
  ],
  exports: [RouterModule],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [AppConfigService],
    },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: APP_BASE_HREF, useValue: '!' },
    Title,
    { provide: Window, useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
