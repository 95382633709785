import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SpinnerOverlayService {
  public showSpinnerOverlay$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public showSpinnerOverlayDelayed$: Observable<boolean> = this.showSpinnerOverlay$.pipe(
    debounceTime(1000)
  );

  public show(): void {
    this.showSpinnerOverlay$.next(true);
  }

  public hide(): void {
    this.showSpinnerOverlay$.next(false);
  }
}
