import { Component } from '@angular/core';
import { UserContextService } from '../../services/user-context-service/user-context.service';
import { mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
})
export class ConsentComponent {
  hasGivenConsent: boolean = false;

  constructor(
    private userContextService: UserContextService,
    private router: Router
  ) {}

  giveConsent(): void {
    this.userContextService
      .get()
      .pipe(
        mergeMap((userContext) => {
          return this.userContextService.giveConsent(userContext.id.toString());
        })
      )
      .subscribe(() => {
        this.router.navigate(['/initial-information']);
      });
  }
}
