<h1>
  {{ "rspWelcomeTitle" | translate }}
</h1>
<div [innerHTML]="'rspWelcomeBody' | translate"></div>
<div class="d-flex justify-content-end">
  <button
    class="btn btn-primary float-end"
    routerLink="/authenticate"
    routerLinkActive="active"
  >
    {{ "rspWelcomeButtonContinue" | translate }}
  </button>
</div>
