import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-language-attribute',
  encapsulation: ViewEncapsulation.None,
  template: '<div *ngIf="(lang$ | async)"></div>',
})
export class LanguageAttributeComponent implements OnInit {
  // https://github.com/ngx-translate/core/issues/565
  lang$: Observable<string> = undefined;

  constructor(
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.lang$ = this.translateService.onLangChange.pipe(
      map((langChangeEvent: LangChangeEvent) => langChangeEvent.lang),
      tap((lang: string) => this.updateLanguage(lang))
    );
  }

  updateLanguage(language: string): void {
    const lang = this.document.createAttribute('lang');
    lang.value = language;
    this.document.querySelector('html').attributes.setNamedItem(lang);
  }
}
