import { Component } from '@angular/core';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '../../services/app-config-service/app-config.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
})
export class ConfirmComponent {
  hasDeclaredAccuracy: boolean = false;
  hasConfirmed: boolean = false;
  activeAssessmentId: string = '';
  language: string = 'en';

  constructor(
    private assessmentService: AssessmentService,
    private appConfigService: AppConfigService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.activeAssessmentId = this.route.snapshot.paramMap.get('assessmentId');
    this.language = this.route.snapshot.paramMap.get('lang');
  }

  confirm() {
    if (this.hasDeclaredAccuracy && this.hasConfirmed) {
      this.assessmentService
        .confirm(this.assessmentService.getCurrentAssessmentWrapperId())
        .subscribe(() => {
          if (this.appConfigService.featureToggles?.showSigningPage) {
            this.router.navigate([
              '/sign',
              this.activeAssessmentId,
              { lang: this.language },
            ]);
          } else {
            this.router.navigate(['/result']);
          }
        });
    }
  }

  goBack(): void {
    this.router.navigate([
      '/assessment',
      this.activeAssessmentId,
      { lang: this.language, readonly: true },
    ]);
  }
}
