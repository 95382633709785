import { Injectable } from '@angular/core';
import { AssessmentState } from '../../models/assessment-wrapper.model';

@Injectable({
  providedIn: 'root',
})
export class StateService {
  getRedirectPathByAssessmentState(state: AssessmentState): string {
    switch (state) {
      case AssessmentState.CREATED:
        return '/consent';
      case AssessmentState.PREPARED:
      case AssessmentState.STARTED:
      case AssessmentState.CONSENTED:
        return '/initial-information';
      case AssessmentState.COMPLETED:
      case AssessmentState.CONFIRMED:
        return '/assessment/readonly=true';
      case AssessmentState.SIGNED:
      case AssessmentState.PROCESSED_MI:
      case AssessmentState.PROCESSED:
        return '/result';
      default:
        return '/welcome';
    }
  }
}
