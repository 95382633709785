import { Injectable, NgZone } from '@angular/core';
import { AuthenticationService } from '../authentication-service/authentication.service';
import { BehaviorSubject } from 'rxjs';
import { AppConfigService } from '../app-config-service/app-config.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutWarningModalComponent } from '../../dialogs/timeout-warning-modal';

const CHECK_INTERVAL = 1000;
const LAST_USER_ACTION_KEY = 'rspLastUserAction';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutServiceService {
  public updatedTimeToTimeout: BehaviorSubject<number>;
  private secondsUntilAutoLogout: number;
  private logoutWarningTriggerOffset: number;
  private modalInstance: NgbModalRef;
  private isWarningModalOpen: boolean = false;

  constructor(
    private authService: AuthenticationService,
    private ngZone: NgZone,
    private appConfigService: AppConfigService,
    private modalService: NgbModal
  ) {
    this.secondsUntilAutoLogout =
      this.appConfigService.configData?.secondsToAutoLogout || 600;
    this.logoutWarningTriggerOffset =
      this.appConfigService.configData
        ?.triggerLogoutWarningSecondsBeforeLogout || 120;
    if (!this.updatedTimeToTimeout) {
      this.updatedTimeToTimeout = new BehaviorSubject<number>(
        this.secondsUntilAutoLogout * 1000
      );
    }
  }

  get lastAction() {
    return parseInt(sessionStorage.getItem(LAST_USER_ACTION_KEY));
  }

  set lastAction(actionTimestamp: number) {
    sessionStorage.setItem(LAST_USER_ACTION_KEY, actionTimestamp.toString());
  }

  initAutoLogoutDetection(): void {
    this.reset();
    this.initListener();
    this.initInterval();
  }

  initListener(): void {
    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener('click', () => this.reset());
      document.body.addEventListener('keydown', () => this.reset());
      document.body.addEventListener('mousemove', () => this.reset());
      document.body.addEventListener('touchstart', () => this.reset());
      document.body.addEventListener('touchmove', () => this.reset());
      document.body.addEventListener('scroll', () => this.reset());
    });
  }

  initInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        this.checkIdleTime();
      }, CHECK_INTERVAL);
    });
  }

  reset(): void {
    this.lastAction = Date.now();
  }

  checkIdleTime(): void {
    const now = Date.now();
    const autoLogoutTime = this.lastAction + this.secondsUntilAutoLogout * 1000;
    const logoutWarningTime =
      autoLogoutTime - this.logoutWarningTriggerOffset * 1000;
    const timeLeftTillAutoLogout = autoLogoutTime - now;
    const timeLeftTillLogoutWarning = logoutWarningTime - now;

    const shouldBeLoggedOut = timeLeftTillAutoLogout < 0;
    const shouldWarningBeShown = timeLeftTillLogoutWarning < 0;

    this.updatedTimeToTimeout.next(timeLeftTillAutoLogout);

    this.ngZone.run(() => {
      if (
        shouldWarningBeShown &&
        this.authService.isUserLoggedIn() &&
        !this.isWarningModalOpen
      ) {
        this.openWarningModal();
      }

      if (shouldBeLoggedOut && this.authService.isUserLoggedIn()) {
        this.authService.logoutUser(true).subscribe();
      }
    });
  }

  private openWarningModal(): void {
    this.modalInstance = this.modalService.open(TimeoutWarningModalComponent, {
      ariaLabelledBy: 'modal-title',
      centered: true,
    });

    this.modalInstance.shown.subscribe(() => (this.isWarningModalOpen = true));
    this.modalInstance.hidden.subscribe(
      () => (this.isWarningModalOpen = false)
    );
  }
}
