import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="modal-header">
      <div class="container modal-header-container">
        <div class="row">
          <div class="col-10 col-sm-11 modal-header-col-left">
            <h4 class="modal-title" id="modal-title">
              {{ 'rspLogoutDialogTitle' | translate }}
            </h4>
          </div>
          <div class="col-2 col-sm-1 modal-header-col-right">
            <button
              id="modalBtnCancelLogout"
              type="button"
              class="btn-close"
              aria-label="Close"
              (click)="activeModal.dismiss()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <p class="modal-body" id="modal-body">
      {{ 'rspLogoutDialogBody' | translate }}
    </p>
    <div class="modal-footer">
      <button
        id="modalBtnSaveAssessment"
        class="btn btn-outline-dark"
        type="button"
        (click)="activeModal.close('save')"
      >
        {{ 'rspLogoutDialogSave' | translate }}
      </button>
      <button
        id="modalBtnDeleteAssessment"
        class="btn btn-outline-dark"
        type="button"
        (click)="activeModal.close('delete')"
      >
        {{ 'rspLogoutDialogDoNotSave' | translate }}
      </button>
    </div>
  `,
})
export class SaveAssessmentModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
