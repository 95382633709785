import { Component, OnDestroy, OnInit } from '@angular/core';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { Attribute, ProductResult } from '../../models/product-result.model';
import { Subject } from 'rxjs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { UrlConfigService } from '../../services/url-config-service/url-config.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
})
export class ResultComponent implements OnInit, OnDestroy {
  productResults: ProductResult[] = [];
  showResultToUser: boolean;
  docUrl: string = '';
  unsubscribe$ = new Subject();
  validAttributeIds: string[];

  constructor(
    private assessmentService: AssessmentService,
    private appConfig: AppConfigService,
    private urlConfigService: UrlConfigService
  ) {
    this.showResultToUser = this.appConfig.featureToggles.showResultToUser;
    this.validAttributeIds = this.appConfig.configData.resultPage.captionAndValue.concat(
      this.appConfig.configData.resultPage.durationMonth,
      this.appConfig.configData.resultPage.percentageLoading,
      this.appConfig.configData.resultPage.textValueOnly
    );
    this.validAttributeIds.push(
      this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
        .duration,
      this.appConfig.configData.resultPage.flatExtraLoadingWithDuration.loading
    );
  }

  ngOnInit(): void {
    const currentAssessmentWrapperId = this.assessmentService.getCurrentAssessmentWrapperId();
    if (currentAssessmentWrapperId) {
      this.assessmentService
        .getResult(currentAssessmentWrapperId)
        .pipe(
          takeUntil(this.unsubscribe$),
          tap((productResults) => {
            this.productResults = productResults;
            this.docUrl = this.urlConfigService.getDocumentServiceDocUrl(
              currentAssessmentWrapperId
            );
          }),
          switchMap(() => {
            return this.assessmentService.postProcess(
              currentAssessmentWrapperId
            );
          })
        )
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  showDetails(attributes: Attribute[]): boolean {
    return !!attributes.find((e) => this.validAttributeIds.includes(e.id));
  }

  filterAttribute(attributes: Attribute[], id: string): Attribute[] {
    return attributes.filter((e) => e.id === id);
  }

  hasDuration(attributes: Attribute[]): boolean {
    return !!attributes.find((e) => e.id === 'FlatExtraDuration');
  }

  isTextValueOnlyTemplate(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return (
      currentAttribute &&
      this.isNotAttributePartOfFlatExtraLoadingWithDurationConfig(
        attributes,
        currentAttribute
      ) &&
      this.appConfig.configData.resultPage.textValueOnly.includes(
        currentAttribute.id
      )
    );
  }

  isCaptionAndValueTemplate(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return (
      currentAttribute &&
      this.isNotAttributePartOfFlatExtraLoadingWithDurationConfig(
        attributes,
        currentAttribute
      ) &&
      this.appConfig.configData.resultPage.captionAndValue.includes(
        currentAttribute.id
      )
    );
  }

  isPercentageLoadingTemplate(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return (
      currentAttribute &&
      this.isNotAttributePartOfFlatExtraLoadingWithDurationConfig(
        attributes,
        currentAttribute
      ) &&
      this.appConfig.configData.resultPage.percentageLoading.includes(
        currentAttribute.id
      )
    );
  }

  isDurationMonthTemplate(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return (
      currentAttribute &&
      this.isNotAttributePartOfFlatExtraLoadingWithDurationConfig(
        attributes,
        currentAttribute
      ) &&
      this.appConfig.configData.resultPage.durationMonth.includes(
        currentAttribute.id
      )
    );
  }

  isPerMilleLoadingTemplate(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return (
      currentAttribute &&
      this.isNotAttributePartOfFlatExtraLoadingWithDurationConfig(
        attributes,
        currentAttribute
      ) &&
      this.appConfig.configData.resultPage.perMilleLoading.includes(
        currentAttribute.id
      )
    );
  }

  isFlatExtraLoadingWithDuration(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return (
      currentAttribute.id ===
        this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
          .duration &&
      this.isFlatExtraLoadingWithDurationInsideAttributes(attributes)
    );
  }

  isNotAttributePartOfFlatExtraLoadingWithDurationConfig(
    attributes: Attribute[],
    currentAttribute: Attribute
  ): boolean {
    return !(
      this.isFlatExtraLoadingWithDurationInsideAttributes(attributes) &&
      (currentAttribute.id ===
        this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
          .loading ||
        currentAttribute.id ===
          this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
            .duration)
    );
  }

  isFlatExtraLoadingWithDurationInsideAttributes(
    attributes: Attribute[]
  ): boolean {
    return (
      this.filterAttribute(
        attributes,
        this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
          .loading
      ).length === 1 &&
      this.filterAttribute(
        attributes,
        this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
          .duration
      ).length === 1
    );
  }

  getFlatExtraLoadingWithDurationCaption(attributes: Attribute[]) {
    return attributes
      .filter(
        (e) =>
          e.id ===
          this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
            .loading
      )
      .pop().value;
  }

  getFlatExtraLoadingWithDurationValue(attributes: Attribute[]) {
    return attributes
      .filter(
        (e) =>
          e.id ===
          this.appConfig.configData.resultPage.flatExtraLoadingWithDuration
            .duration
      )
      .pop().value;
  }
}
