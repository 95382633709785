import { AssessmentWrapperModel } from './assessment-wrapper.model';

export interface UserContext {
  id: string;
  trackingId: string;
  configurationId: string;
  loggedInMessage: string;
  created: Date;
  consented: Date;
  state: UserContextState;
  userContextProvidedData: string;
  assessmentWrappers: AssessmentWrapperModel[];
}

export enum UserContextState {
  CREATED = 'CREATED',
  CONSENTED = 'CONSENTED',
}
