import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntegrationInfo } from '../../models/integration-info.model';
import { ProductResult } from '../../models/product-result.model';
import {
  AssessmentProductView,
  AssessmentWrapperModel,
} from '../../models/assessment-wrapper.model';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { Questionnaire } from '../../models/assessment-questionaire.model';
import { UrlConfigService } from '../url-config-service/url-config.service';

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  public static ASSESSMENT_WRAPPER_ID_COOKIE_KEY = 'currentAssessmentWrapperId';

  private currentAssessmentWrapperId: string;
  private currentAssessmentWrapperIdSubject: BehaviorSubject<string> = new BehaviorSubject(
    undefined
  );
  currentAssessmentWrapperId$: Observable<string> = this.currentAssessmentWrapperIdSubject.asObservable();
  private questionnaireSource: BehaviorSubject<Questionnaire> = new BehaviorSubject(
    undefined
  );
  currentQuestionnaire: Observable<Questionnaire> = this.questionnaireSource.asObservable();

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private cookieService: CookieService,
    private urlConfigService: UrlConfigService
  ) {
    if (
      cookieService.check(AssessmentService.ASSESSMENT_WRAPPER_ID_COOKIE_KEY) &&
      !isNaN(
        Number(
          cookieService.get(AssessmentService.ASSESSMENT_WRAPPER_ID_COOKIE_KEY)
        )
      )
    ) {
      this.updateAssessmentWrapperId(
        cookieService.get(AssessmentService.ASSESSMENT_WRAPPER_ID_COOKIE_KEY)
      );
    }
  }

  getCurrentAssessmentWrapperId(): string {
    return this.currentAssessmentWrapperId;
  }

  setCurrentAssessmentWrapperId(assessmentWrapperId: string) {
    this.updateAssessmentWrapperId(assessmentWrapperId);
    this.cookieService.set(
      AssessmentService.ASSESSMENT_WRAPPER_ID_COOKIE_KEY,
      assessmentWrapperId
    );
  }

  clearCurrentAssessmentWrapperId(): void {
    this.updateAssessmentWrapperId(undefined);
    this.cookieService.delete(
      AssessmentService.ASSESSMENT_WRAPPER_ID_COOKIE_KEY
    );
  }

  publishQuestionnaire(questionnaire: Questionnaire) {
    this.questionnaireSource.next(questionnaire);
  }

  delete(assessmentWrapperId: string): Observable<any> {
    return this.http.delete(
      this.urlConfigService.getAssessmentServiceBaseUrl(assessmentWrapperId)
    );
  }

  start(assessmentWrapperId: string): Observable<IntegrationInfo> {
    return this.http.post<IntegrationInfo>(
      this.urlConfigService.getAssessmentServiceInitUrl(assessmentWrapperId),
      undefined,
      {
        params: { language: this.translateService.currentLang },
      }
    );
  }

  restart(
    assessmentWrapperId: string,
    currentLang: string
  ): Observable<IntegrationInfo> {
    return this.http.post<IntegrationInfo>(
      this.urlConfigService.getAssessmentServiceRestartUrl(assessmentWrapperId),
      undefined,
      {
        params: { language: currentLang },
      }
    );
  }

  confirm(assessmentWrapperId: string): Observable<any> {
    return this.http.post<any>(
      this.urlConfigService.getAssessmentServiceConfirmUrl(assessmentWrapperId),
      undefined
    );
  }

  getResult(assessmentWrapperId: string): Observable<ProductResult[]> {
    return this.http.get<ProductResult[]>(
      this.urlConfigService.getAssessmentServiceResultUrl(assessmentWrapperId)
    );
  }

  completeAssessment(assessmentWrapperId: string): Observable<any> {
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );

    return this.http.post<any>(
      this.urlConfigService.getAssessmentServiceChangeStateUrl(
        assessmentWrapperId
      ),
      'COMPLETED',
      { headers: headers }
    );
  }

  get(assessmentWrapperId: string): Observable<AssessmentWrapperModel> {
    return this.http.get<AssessmentWrapperModel>(
      this.urlConfigService.getAssessmentServiceBaseUrl(assessmentWrapperId)
    );
  }

  getProductViews(
    assessmentWrapperId: string
  ): Observable<AssessmentProductView[]> {
    return this.http.get<AssessmentProductView[]>(
      this.urlConfigService.getAssessmentServiceProductViewUrl(
        assessmentWrapperId
      ),
      {
        params: {
          language: this.translateService.currentLang,
        },
      }
    );
  }

  postProcess(assessmentWrapperId: string): Observable<any> {
    return this.http.post(
      this.urlConfigService.getAssessmentServiceProcessUrl(assessmentWrapperId),
      null,
      { responseType: 'text' }
    );
  }

  private updateAssessmentWrapperId(assessmentWrapperId: string): void {
    this.currentAssessmentWrapperId = assessmentWrapperId;
    this.currentAssessmentWrapperIdSubject.next(assessmentWrapperId);
  }
}
