import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { UserContextState } from '../../models/user-context.model';
import { UserContextService } from '../../services/user-context-service/user-context.service';

@Injectable({ providedIn: 'root' })
export class ConsentGuard implements CanActivate {
  constructor(
    private userContextService: UserContextService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userContextService
      .getAuthenticatedUser()
      .pipe(
        switchMap((user) =>
          iif(
            () => user.state !== UserContextState.CONSENTED,
            of(this.router.parseUrl('/consent')),
            of(true)
          )
        )
      );
  }
}
