<div class="flex-wrapper">
  <div class="center-page">
    <h1>
      {{ "rspSigningTitle" | translate }}
    </h1>
    <iframe
      *ngIf="iframeSrc"
      [src]="iframeSrc"
      id="signFrame"
      name="E-Ident"
      title="E-Ident"
    ></iframe>
  </div>
</div>
