<div
  class="cookie-consent d-flex justify-content-between"
  *ngIf="hasGivenConsent() === false"
>
  <div class="p-2">
    <span>{{ "rspCookieConsentInformation" | translate }}</span>
  </div>
  <div class="p-2">
    <button class="btn btn-primary" (click)="giveConsent()" type="button">
      {{ "rspCookieConsentButtonOk" | translate }}
    </button>
  </div>
</div>

<div class="rsp-header-logo d-flex justify-content-between">
  <div class="p-2">
    <img alt="" [src]="logoLeftSrc" *ngIf="logoLeftSrc" />
  </div>
  <div class="p-2">
    <img alt="" [src]="logoRightSrc" *ngIf="logoRightSrc" />
  </div>
</div>
<nav
  class="navbar navbar-expand-md navbar-dark header-bar"
  aria-label="header-bar"
>
  <div class="container-fluid">
    <span
      class="navbar-brand header-text"
      id="logged-in-message"
      *ngIf="isLoggedIn()"
    >
      {{ loggedInMessage() }}
    </span>
    <button
      class="navbar-toggler ms-auto"
      type="button"
      data-toggle="collapse"
      data-target="#rsp-header-nav-elements"
      aria-controls="rsp-header-nav-elements"
      aria-label="toggle navigation items"
      [attr.aria-expanded]="!isNavbarMenuCollapsed"
      (click)="isNavbarMenuCollapsed = !isNavbarMenuCollapsed"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse"
      [class.collapse]="isNavbarMenuCollapsed"
      id="rsp-header-nav-elements"
    >
      <ul class="navbar-nav ms-auto">
        <li
          *ngIf="isLanguageSwitchPermitted && availableLanguages"
          class="nav-item"
          ngbDropdown
        >
          <button
            aria-expanded="true"
            tabindex="1"
            aria-haspopup="true"
            class="btn dropdown-toggle header-button"
            id="language-switcher"
            ngbDropdownToggle
          >
            {{ currentLanguage | translate }}
          </button>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
            <button
              aria-labelledby="language-switcher"
              ngbDropdownItem
              *ngFor="let languageOption of availableLanguages"
              (click)="changeLanguage(languageOption)"
              [lang]="languageOption"
            >
              {{ languageOption | translate }}
            </button>
          </div>
        </li>
        <ng-container *ngIf="isLoggedIn()">
          <li class="nav-item" id="saveAndLogoutNavItem">
            <button
              *ngIf="showSaveAndLogoutButton"
              id="saveAndLogout"
              tabindex="0"
              class="btn btn-default header-button"
              (click)="logout(true)"
            >
              {{ "rspHeaderSaveAndLogout" | translate }}
            </button>
          </li>
          <li class="nav-item" id="logoutNavItem">
            <button
              id="logout"
              tabindex="0"
              class="btn btn-default header-button"
              (click)="logout()"
            >
              {{ "rspHeaderLogout" | translate }}
            </button>
            <span class="header-logout-timer header-button">
              {{
                timeToTimeout$ ? (timeToTimeout$ | async | date: "mm:ss") : ""
              }}
            </span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>
</nav>
<div class="mx-0 row">
  <div class="col-12 progress">
    <div
      class="progress-bar"
      id="progressBar"
      role="progressbar"
      aria-label="progressbar"
      [style.width]="progress"
    ></div>
  </div>
</div>
