import { Component, OnInit, ViewChild } from '@angular/core';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { ResourceService } from '../../services/resource-service/resource.service';
import { UserContextService } from '../../services/user-context-service/user-context.service';
import { KbProductList } from '../../models/kb-product-list.model';
import { UserContext } from '../../models/user-context.model';
import { filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
  DefaultUrlSerializer,
  PRIMARY_OUTLET,
  Router,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import {
  AssessmentProductView,
  AssessmentState,
  AssessmentWrapperModel,
} from '../../models/assessment-wrapper.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, NEVER, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';

@Component({
  selector: 'app-initial-information',
  templateUrl: './initial-information.component.html',
})
export class InitialInformationComponent implements OnInit {
  rspInitialInformationTitle = 'Initial information';
  rspInitialInformationPrepare = 'Create';
  kbProductList: KbProductList = { products: [] };
  userContext: UserContext = {
    id: null,
    consented: null,
    created: null,
    loggedInMessage: '',
    configurationId: '',
    state: null,
    trackingId: '',
    userContextProvidedData: '',
    assessmentWrappers: [],
  };

  internalPrepareAllowed: boolean;
  addAssessmentEnabled: boolean = false;
  hideRiders: boolean = false;

  @ViewChild('deleteAssessmentModal') deleteAssessmentModal: any;
  @ViewChild('restartAssessmentModal') restartAssessmentModal: any;

  constructor(
    private resourceService: ResourceService,
    private userContextService: UserContextService,
    private assessmentService: AssessmentService,
    private router: Router,
    private modalService: NgbModal,
    private translateService: TranslateService,
    private appConfig: AppConfigService,
    private authService: AuthenticationService,
    private appConfigService: AppConfigService
  ) {}

  ngOnInit(): void {
    this.internalPrepareAllowed = this.appConfig.featureToggles?.internalPrepareFeatureToggle;
    this.hideRiders = this.appConfig.featureToggles?.hideRiders;

    const productsApiCall = this.resourceService.getProductSetup(
      this.translateService.currentLang
    );
    const userContextApiCall = this.userContextService.get();

    forkJoin([productsApiCall, userContextApiCall])
      .pipe(
        tap(([productList, userContext]) => {
          this.kbProductList = productList;
        }),
        mergeMap(([productList, userContext]) =>
          this.handleNewUserContext(userContext)
        )
      )
      .subscribe();
  }

  prepareAssessment(): void {
    this.userContextService
      .internalPrepare(this.userContext.id, this.kbProductList)
      .pipe(
        tap(() => {
          this.addAssessmentEnabled = false;
          this.uncheckAllProductBoxes();
        }),
        mergeMap((newUserContext) => this.handleNewUserContext(newUserContext))
      )
      .subscribe();
  }

  deleteAssessment(assessmentWrapperId: string): void {
    this.modalService
      .open(this.deleteAssessmentModal, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
      })
      .closed.pipe(
        filter((confirmAction) => confirmAction === 'delete'),
        mergeMap(() => this.assessmentService.delete(assessmentWrapperId)),
        mergeMap(() => this.userContextService.get()),
        tap(() => {
          this.addAssessmentEnabled = false;
          this.uncheckAllProductBoxes();
        }),
        mergeMap((newUserContext) => this.handleNewUserContext(newUserContext))
      )
      .subscribe();
  }

  starRestartOrInitializeAssessment(assessmentWrapperId: string): void {
    this.assessmentService
      .get(assessmentWrapperId)
      .pipe(
        switchMap((wrapper) => {
          switch (wrapper.state) {
            case AssessmentState.PREPARED:
            case AssessmentState.STARTED: {
              if (wrapper.directUrl === null) {
                return this.startAssessmentAndNavigate(assessmentWrapperId);
              } else if (
                wrapper.assessmentLanguage !== this.translateService.currentLang
              ) {
                return this.showLanguageSwitchModalAndNavigate(
                  assessmentWrapperId,
                  this.translateService.currentLang
                );
              } else {
                return this.authService
                  .refreshCepToken(wrapper.assessmentId)
                  .pipe(
                    tap(() => {
                      this.navigateToAssessment(
                        wrapper.directUrl,
                        assessmentWrapperId
                      );
                    })
                  );
              }
            }
            case AssessmentState.COMPLETED: {
              return of({}).pipe(
                tap(() => {
                  this.router.navigate([
                    '/confirm',
                    wrapper.assessmentId,
                    { lang: wrapper.assessmentLanguage },
                  ]);
                })
              );
            }
            case AssessmentState.CONFIRMED: {
              if (this.appConfigService.featureToggles?.showSigningPage) {
                return of({}).pipe(
                  tap(() => {
                    this.router.navigate([
                      '/sign',
                      wrapper.assessmentId,
                      { lang: wrapper.assessmentLanguage },
                    ]);
                  })
                );
              } else {
                this.viewResult(assessmentWrapperId);
              }
            }
          }
        })
      )
      .subscribe(() => {});
  }

  private startAssessmentAndNavigate(
    assessmentWrapperId: string
  ): Observable<void> {
    return this.assessmentService
      .start(assessmentWrapperId)
      .pipe(
        map((info) =>
          this.navigateToAssessment(info.directUrl, assessmentWrapperId)
        )
      );
  }

  private reStartAssessmentWithLanguage(
    assessmentWrapperId: string,
    language: string
  ): Observable<string> {
    return this.assessmentService
      .restart(assessmentWrapperId, language)
      .pipe(map((info) => info.directUrl));
  }

  private showLanguageSwitchModalAndNavigate(
    assessmentWrapperId: string,
    language: string
  ): Observable<string> {
    return this.modalService
      .open(this.restartAssessmentModal, {
        ariaLabelledBy: 'restart-assessment-title',
        centered: true,
      })
      .closed.pipe(
        switchMap((result) => {
          if (result === 'restart') {
            return this.reStartAssessmentWithLanguage(
              assessmentWrapperId,
              language
            );
          } else {
            return NEVER;
          }
        })
      )
      .pipe(
        tap((url) => {
          this.navigateToAssessment(url, assessmentWrapperId);
        })
      );
  }

  private navigateToAssessment(
    assessmentUrl: string,
    assessmentWrapperId: string
  ): void {
    let url: string = assessmentUrl;
    if (url.startsWith('#!')) {
      url = url.replace('#!', '');
    }
    const tree: UrlTree = new DefaultUrlSerializer().parse(url);
    const urlSegments: UrlSegment[] =
      tree.root.children[PRIMARY_OUTLET].segments;

    if (urlSegments.length === 2 && 'assessment' === urlSegments[0].path) {
      this.assessmentService.setCurrentAssessmentWrapperId(assessmentWrapperId);
      this.router.navigate([
        '/assessment',
        urlSegments[1].path,
        tree.queryParams,
      ]);
    }
  }

  viewResult(assessmentWrapperId: string): void {
    this.assessmentService.setCurrentAssessmentWrapperId(assessmentWrapperId);
    this.router.navigate(['/result']);
  }

  validateProductSelection(): void {
    this.addAssessmentEnabled = false;

    this.kbProductList.products.forEach((product) => {
      if (product.checked) {
        product.riders?.forEach((rider) => {
          if (rider.mandatory) {
            rider.checked = true;
            rider.notAvailable = true;
          }
        });

        this.addAssessmentEnabled = true;
      } else {
        product.riders?.forEach((rider) => {
          rider.checked = false;
          rider.notAvailable = false;
        });
      }
    });
  }

  isAssessed(assessmentWrapper: AssessmentWrapperModel): boolean {
    return (
      assessmentWrapper.state === AssessmentState.SIGNED ||
      assessmentWrapper.state === AssessmentState.PROCESSED_MI ||
      assessmentWrapper.state === AssessmentState.PROCESSED
    );
  }

  private handleNewUserContext(
    newUserContext: UserContext
  ): Observable<AssessmentProductView[][]> {
    this.userContext = newUserContext;
    return this.handleProductViewsForAssessmentWrapper();
  }

  private uncheckAllProductBoxes(): void {
    this.kbProductList.products.forEach((product) => {
      product.checked = false;
      product.riders?.forEach((rider) => (rider.checked = false));
    });
  }

  private handleProductViewsForAssessmentWrapper(): Observable<
    AssessmentProductView[][]
  > {
    const observables: Observable<AssessmentProductView[]>[] = [];

    this.kbProductList.products.forEach((product) => {
      product.notAvailable = false;
    });

    this.userContext.assessmentWrappers.forEach((wrapper) => {
      observables.push(
        this.assessmentService
          .getProductViews(wrapper.id)
          .pipe(tap((productViews) => (wrapper.productViews = productViews)))
      );
    });

    return forkJoin(observables).pipe(
      tap((productViews: Array<AssessmentProductView[]>) =>
        this.disableAlreadyChosenProducts(productViews)
      )
    );
  }

  private disableAlreadyChosenProducts(
    productViewsList: Array<AssessmentProductView[]>
  ): void {
    const createdProductAssessments = [];
    productViewsList.forEach((productViews) => {
      productViews.forEach((singleProduct) =>
        createdProductAssessments.push(singleProduct.id)
      );
    });

    this.kbProductList.products.forEach((product) => {
      product.notAvailable = createdProductAssessments.indexOf(product.id) > -1;
    });
  }
}
