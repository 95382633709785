<h1>
  {{ (!summaryMode ? "rspAssessmentHeading" : "rspReviewHeading") | translate }}
</h1>
<form class="mx-1 rsp-assessment" [class.review-form]="summaryMode">
  <cep-web-questionnaire
    #questionnaireComponent
    *ngIf="activeAssessmentId"
    [assessmentId]="activeAssessmentId"
    [config]="questionnaireConfig"
    [summaryMode]="summaryMode"
    [language]="language"
    [translations]="translations"
    [paginationMode]="paginationMode"
    [activePageId]="activePageId"
    [arsp-containerpplication]="applicationData"
    [rtl]="isRtl"
    [authorizationHandler]="renewToken"
    (activePageIdChange)="activePageId = $event.detail"
    (notification)="onNotification($event.detail)"
    (initializedChange)="onQuestionnaireInit()"
    (assessmentFinished)="onAssessmentFinished()"
    (applicationChange)="applicationData = $event.detail"
  >
    <div slot="before">
      <cep-web-question-reminder [offset]="54"></cep-web-question-reminder>
      <cep-web-blocked-pagination-alert></cep-web-blocked-pagination-alert>
      <cep-web-breadcrumb-pagination></cep-web-breadcrumb-pagination>
      <cep-web-prev-next-pagination></cep-web-prev-next-pagination>
    </div>
    <div
      slot="after"
      class="d-flex justify-content-between overflow-x-auto rsp-assessment-bottom-navigation flex-column flex-sm-row"
    >
      <cep-web-full-page-pagination></cep-web-full-page-pagination>
      <cep-web-questionnaire-legend></cep-web-questionnaire-legend>
      <ng-container *ngIf="!summaryMode">
        <cep-web-submit-button
          class="align-self-end align-self-sm-auto"
        ></cep-web-submit-button>
      </ng-container>
    </div>
  </cep-web-questionnaire>

  <div *ngIf="summaryMode" class="d-flex justify-content-between">
    <button class="btn btn-secondary" (click)="editQuestionnaire()">
      {{ "rspAssessmentButtonBack" | translate }}
    </button>
    <button
      (click)="goToConfirmPage()"
      routerLinkActive="active"
      class="btn btn-primary"
      id="btn-continue-next-page"
    >
      {{ "rspAssessmentButtonContinue" | translate }}
    </button>
  </div>
</form>
