import { Component, Injectable, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent {
  @Input() modalBodyKey: string;
  @Input() modalTitleKey: string;
  @Input() modalButtonCloseKey: string;
  @Input() modalButtonCancelKey: string;

  constructor(public activeModal: NgbActiveModal) {}
}
