import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { UserContext } from '../../models/user-context.model';
import { ProvidedData } from '../../models/provided-data.model';
import { KbProductList } from '../../models/kb-product-list.model';
import { SelectedProducts } from '../../models/selected-products.model';
import { UrlConfigService } from '../url-config-service/url-config.service';

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  loggedInUser: UserContext;

  constructor(
    private http: HttpClient,
    private urlConfigService: UrlConfigService
  ) {
    this.getAuthenticatedUser().subscribe((user) => {
      this.loggedInUser = user;
    });
  }

  get(userContextId?: string): Observable<UserContext> {
    return this.http.get<UserContext>(
      this.urlConfigService.getUserContextServiceBaseUrl(
        userContextId ? userContextId : ''
      )
    );
  }

  internalPrepare(
    userContextId: string,
    productList: KbProductList
  ): Observable<UserContext> {
    const url: string = this.urlConfigService.getUserContextServiceInternalPrepareUrl(
      userContextId
    );

    let formattedDate: string = new DatePipe('en-US').transform(
      new Date(),
      'YYYY-MM-dd'
    );

    let products: SelectedProducts = {};
    for (let product of productList.products) {
      if (product.checked) {
        products[product.id] = { riders: {} };
        if (product.riders) {
          for (let r of product.riders) {
            if (r.checked) {
              products[product.id].riders[r.id] = {};
            }
          }
        }
      }
    }

    const providedData: ProvidedData = {
      source: { id: 'RSP', type: 'CustomerPortal', url: '#!/review' },
      products: products,
      questionnaire: {
        ApplicationDate: {
          answer: formattedDate,
        },
      },
    };

    return this.http.post<UserContext>(url, providedData);
  }

  getAuthenticatedUser(): Observable<UserContext> {
    return this.http.get<UserContext>(
      this.urlConfigService.getUserContextServiceAuthenticatedUrl()
    );
  }

  giveConsent(userContextId: string): Observable<any> {
    return this.http.post(
      this.urlConfigService.getUserContextServiceConsentUrl(userContextId),
      undefined,
      { responseType: 'text' }
    );
  }
}
