import { Component, OnInit } from '@angular/core';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../services/util-service/util.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { AppConfigService } from '../../services/app-config-service/app-config.service';

@Component({
  selector: 'app-sign',
  templateUrl: './sign.component.html',
})
export class SignComponent implements OnInit {
  iframeSrc: SafeResourceUrl;
  activeAssessmentId: string = '';
  language: string = 'en';

  constructor(
    private authenticationService: AuthenticationService,
    private config: AppConfigService,
    private assessmentService: AssessmentService,
    private translateService: TranslateService,
    private utils: UtilService,
    private sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public route: ActivatedRoute
  ) {
    this.iframeSrc = sanitizer.bypassSecurityTrustResourceUrl('');
    this.activeAssessmentId = this.route.snapshot.paramMap.get('assessmentId');
    this.language = this.route.snapshot.paramMap.get('lang');
  }

  ngOnInit(): void {
    this.authenticationService
      .getSignPath(
        this.assessmentService.getCurrentAssessmentWrapperId(),
        this.translateService.currentLang
      )
      .subscribe((signPath) => {
        if (this.config.configData.externalSign) {
          window.location.href = signPath;
        } else {
          this.iframeSrc = this.utils.handleIFrameUrl(signPath);
        }
      });
    this.route.queryParams.subscribe((p) => {
      if (p?.error) {
        this.showErrorModal(p.error);
      }
    });
  }

  showErrorModal(modalBodyKey: string): NgbModalRef {
    let modal: NgbModalRef = this.modalService.open(ModalComponent, {});
    modal.componentInstance.modalBodyKey = modalBodyKey;
    modal.componentInstance.modalTitleKey = 'rspModalGeneralHeading';
    modal.componentInstance.modalButtonCloseKey = 'rspModalButtonClose';
    return modal;
  }
}
